import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import _ from 'lodash'
import { SeoPageGeneral } from '@/content/seo/manage.seo'
const NavbarDefault = dynamic(() => import('@/component/layout/NavbarDefault'))
const Footer = dynamic(() => import('@/component/layout/Footer'))

// Default
const Wrap = ({
   children,
   content = {},
   isWithoutContent = false,
   seo = {},
   isNavBlackStyle = false,
   isWithoutFooter = false,
   isBgSuccessPage = false,
   isCheckCoveragePlatform = false,
   isCanonicalLocale = false,
}) => {
   const defaultLocale = 'en'
   const router = useRouter()
   const locale = router.locale

   const canonical =
      isCanonicalLocale && locale !== defaultLocale
         ? router.asPath === '/'
            ? router.asPath + locale
            : '/' + locale + router.asPath
         : router.asPath

   return (
      <>
         <SeoPageGeneral
            canonical={canonical}
            isAddManually={seo.isAddManually || false}
            configSeo={seo}
            configOpenGraph={seo}
            locale={locale}
            isCanonicalLocale={isCanonicalLocale}
         />
         <NavbarDefault
            isNavBlackStyle={isNavBlackStyle}
            isNavbarFixed
            isCheckCoveragePlatform={isCheckCoveragePlatform}
         />
         {!_.isEmpty(content) || isWithoutContent ? (
            <main
               style={
                  isBgSuccessPage
                     ? {
                          zIndex: 1,
                          backgroundColor: '#171718',
                          overflowX: 'hidden',
                       }
                     : { zIndex: 1, overflowX: 'hidden' }
               }>
               {children}
            </main>
         ) : null}
         {!isWithoutFooter ? <Footer /> : null}
      </>
   )
}

export default Wrap
